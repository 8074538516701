import { App, Popconfirm } from "antd";
import { apiMethod } from "api/transaction";
import TableComponent from "components/table";
import PageContainer from "layouts/components/pageContainer";
import { Fragment, useState } from "react";
import { formattedDateTime } from "utils/dateFormat";
import { BrandTransactionStatus } from "utils/enum";
import { CheckOutlined, CheckCircleOutlined } from "@ant-design/icons";
import InvoiceIcon from '../../assets/images/InvoiceIcon.svg'
import moment from "moment";


const TransactionHistory = () => {
  const { message } = App.useApp();
  const [reloadTable, setreloadTable] = useState(0);
  const [loaderInvoice, setLoaderInvoice] = useState(false);


  const calculateCampaignStatus = (campaignCompletedDate?: any) => {
    //.add(60, 'days')
    const paymentDueDate = moment(campaignCompletedDate)
    const currentDate = moment().startOf('day'); // Get the current date at the start of the day
    const daysDifference = paymentDueDate.diff(currentDate, 'days'); // Difference in days between payment due date and today
    if (daysDifference === 60) {
      return `Due in ${daysDifference} days`;
      // return `Payment Due`;
    } else if (daysDifference > 3) {
      return `Due in ${daysDifference} days`;
    } else if (daysDifference > 0 && daysDifference >= 3) {

      return `Overdue ${3 - daysDifference + 1} days`;
    } else if (daysDifference > 0 && daysDifference > 3) {

      return `Overdue on ${paymentDueDate.format('MM/DD/YY')}`;
    } else if (daysDifference === 0) {
      return `${paymentDueDate.format('MM/DD/YY')}`;
    } else {

      return `Overdue on ${paymentDueDate.format('MM/DD/YY')}`;
    }
  };

  const updatePaymentStatus = async (
    id: number,
    status: number,
    brandId: number,
    price: number
  ) => {
    apiMethod
      .updatePaymentStatus(id, status, brandId, price)
      .then((res: any) => {
        message.success("Payment status updated successfully.");
        setreloadTable((reloadTable) => reloadTable + 1);
      })
      .catch((err: any) => { });
  };


  const handleDownload = (pdfPath: string) => {
    const fileName = pdfPath.split("/").pop();
    if (fileName) {
      const a = document.createElement("a");
      a.setAttribute("href", pdfPath);
      a.setAttribute("download", fileName);
      a.click();
    }
  };

  const handleGenerateInvoice = (campaignId: string) => {
    setLoaderInvoice(true);
    const payload = { campaignId };
    apiMethod.downloadInvoiceCampaign(payload)
      .then(() => {
        setLoaderInvoice(false);
        message.success("Invoice generated successfully.");
        setreloadTable((prev) => prev + 1);
      })
      .catch(() => setLoaderInvoice(false));
  };


  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      sorter: true,
      dataType: "text",
      render: (value: string) => {
        return <span> {formattedDateTime(value)}</span>;
      },
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Brand Name",
      dataIndex: "brandEntityName",
      sorter: false,
      dataType: "text",
    },
    {
      title: "Campaign / Purchase Order",
      dataType: "text",
      render: (value: any, row: any, index: number) => {
        return (
          <div>
            {row?.campaign?.title ? row?.campaign?.title : "Purchase Order"}
          </div>
        )
      }
    },
    {
      title: "Daps",
      dataIndex: "daps",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      sorter: true,
      dataType: "text",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (value: any, row: any, index: number) => {
        return (
          <Fragment>
            {
              value === 1 ?
                <span className="green-color">Completed</span>
                : value === 2 ?
                  <span className="yellow-color">Continue</span>
                  : value === 3 ?
                    <span className="yellow-color">In Progress</span>
                    :
                    value === 5 ?
                      <span className="purple-color">Scheduled</span>
                      :
                      [4, 6, 7, 8]?.includes(value) ?
                        <span className="notInvited text-start">{row?.dueDate ? calculateCampaignStatus(moment(row?.dueDate)) : calculateCampaignStatus(moment(row?.campaign?.completedAt).add(60, 'days'))}</span>
                        : value === 9 ? <span className="green-color">Paid</span> : value === 10 ? "Invoice Updated" : value === 11 ? 'Invoice cancelled' : ''
            }
            {/* {value && value === BrandTransactionStatus.Unpaid ? (
              <Popconfirm
                placement="bottom"
                title={"Update the Payment status?"}
                description={"Are you sure to update the payment status to 'Paid'?"}
                ok-text="Yes"
                cancel-text="No"
                onConfirm={() =>
                  updatePaymentStatus(
                    row.id,
                    BrandTransactionStatus.Paid,
                    row.brandId,
                    row.amount,
                  )
                }
                icon={<CheckCircleOutlined style={{ color: "#237804" }} />}
              >
                <CheckOutlined style={{ color: "#6cb557" }} title="Unpaid" />
              </Popconfirm>
            ) : (
              <span className="text-verified">Paid</span>
            )} */}
          </Fragment>
        );
      },
    },
    {
      title: "Download Invoice",
      dataIndex: "amount",
      sorter: true,
      dataType: "text",
      render: (value: any, row: any, index: number) => {
        return (
          <>
            {([4, 6].includes(row.status) && !row.pdfPath) && Number(row?.amount) > 0 && (
              <button
                className="genrate_invoice_btn"
                onClick={() => handleGenerateInvoice(row?.campaignId)}
              >
                Generate Invoice
              </button>
            )}

            {[1, 4, 6, 7, 8, 9].includes(row.status) && row.pdfPath && (
              <img
                src={InvoiceIcon}
                alt="Invoice"
                className="invoice-icon"
                style={{ cursor: "pointer" }}
                onClick={() => handleDownload(row.pdfPath)}
              />
            )}
          </>
        )
      }
    },
  ];

  return (
    <PageContainer title={"Transaction History"}>
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        columns={columns}
        reloadTable={reloadTable}
        otherGetDataFunc={(obj: any) => {
          //  obj["type"] = 1;
          return obj;
        }}
        customAction={true}
      />
    </PageContainer>
  );
};

export default TransactionHistory;
